import { batchEdit, edit } from '@/Devices/api'
import { ServerDevice } from '@/Devices/types'
import { EditPencilIcon } from '@/components/EditPencilIcon'
import { ModalContainer } from '@/components/ModalContainer'
import { OrderIcon } from '@/components/OrderIcon'
import { StatusIcon } from '@/components/StatusIcon'
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import * as React from 'react'
import { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'
import { HighFlowLeakAlert } from '../HighFlowLeakAlert'
import { LeakAlert } from '../LeakAlert'
import { DeviceSettings } from '../DeviceSettings'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles'
import { DialogMenu } from '../DialogMenu'
import { DeleteConfirmDialog } from '../Dialog'
import { convertTimestampToTimeZone } from '@/util'
import { Structure } from '@/Structures/types'
import useMixPanel from '@/hooks/useMixPanel'

const DEFAULT_BATCH_EDIT_LEAK_FLOW_THRESHOLD_GALLONS = Number(
  process.env.REACT_APP_DEFAULT_BATCH_EDIT_LEAK_FLOW_THRESHOLD_GALLONS
)

const DEFAULT_BATCH_EDIT_LEAK_THRESHOLD_HOURS = Number(process.env.REACT_APP_DEFAULT_BATCH_EDIT_LEAK_THRESHOLD_HOURS)
const DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_THRESHOLD_GALLONS = Number(
  process.env.REACT_APP_DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_THRESHOLD_GALLONS
)
const DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_WINDOW_SECONDS = Number(
  process.env.REACT_APP_DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_WINDOW_SECONDS
)
const DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_DISABLE_SECONDS = Number(
  process.env.REACT_APP_DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_DISABLE_SECONDS
)

const CustomLabelSort = styled('span')({
  position: 'absolute',
  top: '50%',
  right: '-15px',
  transform: 'translate(-50%, -50%)'
})

enum STATUS_COLORS {
  OKAY = '#00e676',
  WARNING = '#ffc400',
  ERROR = '#e53935'
}

export enum SECONDS_CONSTANT {
  SECONDS_IN_AN_HOUR = 3600
}

interface DeviceStatusTableProps {
  devices: ServerDevice[]
  activateMultipleSelectors?: boolean
  refreshData: () => void
  timeZone: string
  structure: Structure
  unitInfo?: { unit_id: string; unit_name: string }
}

export enum DEVICE_SETTINGS_CONSTANTS {
  LEAK_THR_HOURS_MAX = 72,
  LEAK_THR_HOURS_MIN = 1,
  ALERT_WINDOW_HOUR_MAX = 24,
  ALERT_WINDOW_HOUR_MIN = 1,
  ALERT_THR_GALL_MIN = 0
}

const initialDevice: ServerDevice = {
  id: '',
  name: '',
  serial_number: '',
  device_status: 'ERROR',
  device_status_strings: [],
  leak_enabled: false,
  desired_usage_alert_enabled: false,
  is_demo: false,
  ix: 0,
  last_seen: 0,
  last_updated: 0,
  access_pin: '',
  leak_flow_threshold_gallons: DEFAULT_BATCH_EDIT_LEAK_FLOW_THRESHOLD_GALLONS,
  leak_threshold_hours: DEFAULT_BATCH_EDIT_LEAK_THRESHOLD_HOURS,
  desired_usage_alert_threshold_gallons: DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_THRESHOLD_GALLONS,
  desired_usage_alert_window_seconds: DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_WINDOW_SECONDS,
  desired_usage_alert_disable_seconds: DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_DISABLE_SECONDS
}

const initialSort = {
  unitSortValue: '',
  nameSortValue: '',
  statusSortValue: '',
  messageSortValue: '',
  leakAlertValue: '',
  flowLeakValue: ''
}

const BatchEditMenu = ({
  openGlobalLeakAlert,
  openGlobalHighLeakAlert,
  openGlobalHighLeakAlertDialog,
  openGlobalLeakAlertDialog
}) => {
  return (
    <>
      <MenuItem onClick={openGlobalLeakAlert}>Enable leak alerts</MenuItem>
      <MenuItem onClick={openGlobalHighLeakAlert}>Enable high flow leak alerts</MenuItem>
      <MenuItem onClick={openGlobalLeakAlertDialog}>Disable leak alerts</MenuItem>
      <MenuItem onClick={openGlobalHighLeakAlertDialog}>Disable high flow leak alerts</MenuItem>
    </>
  )
}

export const DeviceStatusTable = ({
  devices,
  refreshData,
  activateMultipleSelectors = false,
  timeZone,
  structure,
  unitInfo
}: DeviceStatusTableProps): JSX.Element => {
  const [sortValues, setSortValues] = useState(initialSort)
  const [deviceSelected, setDeviceSelected] = useState<ServerDevice>(initialDevice)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openLeakAlertModal, setOpenLeakAlertModal] = useState(false)
  const [openHighLeakAlertModal, setOpenHighLeakAlertModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [selectRowIndex, setSelectRowIndex] = useState<number>(-1)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [mouseX, setMouseX] = useState<number>()
  const [mouseY, setMouseY] = useState<number>()
  const [openHflaAlert, setOpenHflaAlert] = useState(false)
  const [openLaAlert, setOpenLaAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [eventStatus, setEventStatus] = useState({ leakAlertUpdated: false, highLeakAlertUpdated: false })
  const open = Boolean(anchorEl)
  const { trackEvent } = useMixPanel()

  const handleOpenEditModal = (device) => {
    setOpenEditModal(true)
    setDeviceSelected({
      ...device
    })
  }

  function sortColumns(key: string) {
    let order = { ...sortValues }

    if (sortValues[key]) {
      if (sortValues[key] === 'asc') order[key] = 'desc'
      if (sortValues[key] === 'desc') order[key] = ''
    } else {
      order = { ...initialSort, [key]: 'asc' }
    }

    setSortValues(order)
  }

  const comparateValues = (a, b) => {
    const unitNameA = a?.unit_name?.toUpperCase()
    const unitNameB = b?.unit_name?.toUpperCase()
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    const statusA = a.device_status.toUpperCase()
    const statusB = b.device_status.toUpperCase()
    const messageA = a.device_status_strings.join(',').toUpperCase()
    const messageB = b.device_status_strings.join(',').toUpperCase()
    const leakAlertA = a.leak_enabled
    const leakAlertB = b.leak_enabled
    const flowLeakAlertA = a.desired_usage_alert_enabled
    const flowLeakAlertB = b.desired_usage_alert_enabled

    if (sortValues.unitSortValue) {
      if (unitNameA < unitNameB) {
        return sortValues.unitSortValue === 'desc' ? -1 : 1
      }
      if (unitNameA > unitNameB) {
        return sortValues.unitSortValue === 'desc' ? 1 : -1
      }
    }

    if (sortValues.nameSortValue) {
      if (nameA < nameB) {
        return sortValues.nameSortValue === 'desc' ? -1 : 1
      }
      if (nameA > nameB) {
        return sortValues.nameSortValue === 'desc' ? 1 : -1
      }
    }

    if (sortValues.statusSortValue) {
      if (statusA < statusB) {
        return sortValues.statusSortValue === 'desc' ? -1 : 1
      }
      if (statusA > statusB) {
        return sortValues.statusSortValue === 'desc' ? 1 : -1
      }
    }

    if (sortValues.messageSortValue) {
      if (messageA < messageB) {
        return sortValues.messageSortValue === 'desc' ? -1 : 1
      }
      if (messageA > messageB) {
        return sortValues.messageSortValue === 'desc' ? 1 : -1
      }
    }

    if (sortValues.leakAlertValue) {
      return (leakAlertB - leakAlertA) * (sortValues.leakAlertValue === 'desc' ? 1 : -1)
    }

    if (sortValues.flowLeakValue) {
      return (flowLeakAlertB - flowLeakAlertA) * (sortValues.flowLeakValue === 'desc' ? 1 : -1)
    }

    return 0
  }

  const devicesSortArray = devices.sort(comparateValues)

  const updateDeviceData = (value, key) => {
    const patternToIdentifyNumbers = /^\d+$/
    if (
      (key === 'leak_threshold_hours' ||
        key === 'desired_usage_alert_window_seconds' ||
        key === 'desired_usage_alert_threshold_gallons') &&
      !!value
    ) {
      if (!patternToIdentifyNumbers.test(value)) return
    }
    setDeviceSelected({ ...deviceSelected, [key]: value })
    if (key === 'leak_threshold_hours' && value) {
      setEventStatus({ ...eventStatus, leakAlertUpdated: true })
      if (value > DEVICE_SETTINGS_CONSTANTS.LEAK_THR_HOURS_MAX) {
        setDeviceSelected({ ...deviceSelected, [key]: DEVICE_SETTINGS_CONSTANTS.LEAK_THR_HOURS_MAX })
      }

      if (value < DEVICE_SETTINGS_CONSTANTS.LEAK_THR_HOURS_MIN) {
        setDeviceSelected({ ...deviceSelected, [key]: DEVICE_SETTINGS_CONSTANTS.LEAK_THR_HOURS_MIN })
      }
    }
    if (key === 'desired_usage_alert_window_seconds' && value) {
      setEventStatus({ ...eventStatus, highLeakAlertUpdated: true })
      if (value > DEVICE_SETTINGS_CONSTANTS.ALERT_WINDOW_HOUR_MAX * SECONDS_CONSTANT.SECONDS_IN_AN_HOUR)
        setDeviceSelected({
          ...deviceSelected,
          [key]: DEVICE_SETTINGS_CONSTANTS.ALERT_WINDOW_HOUR_MAX * SECONDS_CONSTANT.SECONDS_IN_AN_HOUR
        })
      if (value < DEVICE_SETTINGS_CONSTANTS.ALERT_WINDOW_HOUR_MIN * SECONDS_CONSTANT.SECONDS_IN_AN_HOUR)
        setDeviceSelected({
          ...deviceSelected,
          [key]: DEVICE_SETTINGS_CONSTANTS.ALERT_WINDOW_HOUR_MIN * SECONDS_CONSTANT.SECONDS_IN_AN_HOUR
        })
    }
    if (key === 'desired_usage_alert_threshold_gallons' && value) {
      setEventStatus({ ...eventStatus, highLeakAlertUpdated: true })
      if (value < DEVICE_SETTINGS_CONSTANTS.ALERT_THR_GALL_MIN) {
        setDeviceSelected({ ...deviceSelected, [key]: DEVICE_SETTINGS_CONSTANTS.ALERT_THR_GALL_MIN })
      }
    }
    if (key === 'leak_enabled' && value) {
      setOpenLeakAlertModal(true)
    }
    if (key === 'desired_usage_alert_enabled' && value) {
      setOpenHighLeakAlertModal(true)
    }
  }

  const saveDeviceData = async (closeModal) => {
    setLoading(true)

    if (openLeakAlertModal) {
      if (!deviceSelected.leak_threshold_hours) {
        toast.error("you can't update a device without a leak duration")
        setLoading(false)
        return
      }
    }
    if (openHighLeakAlertModal) {
      if (!deviceSelected.desired_usage_alert_threshold_gallons) {
        toast.error("you can't update a device without a usage above")
        setLoading(false)
        return
      }
      if (!deviceSelected.desired_usage_alert_window_seconds) {
        toast.error("you can't update a device without a usage window")
        setLoading(false)
        return
      }
    }
    if (openEditModal) {
      if (!deviceSelected.name) {
        toast.error("you can't update a device without a name")
        setLoading(false)
        return
      }
      const updatedDevicesResponse = await edit(deviceSelected)
      if (eventStatus.highLeakAlertUpdated)
        trackEvent('High Flow Leak Alert Updated', {
          UsageAbove: deviceSelected.leak_flow_threshold_gallons + ' gal',
          UsageWindow: deviceSelected.desired_usage_alert_window_seconds + ' seconds',
          LeakDuration: deviceSelected?.desired_usage_alert_window_seconds + ' seconds',
          PropertyName: structure.name,
          PropertyID: structure.id,
          UnitName: unitInfo ? unitInfo.unit_name : deviceSelected?.unit_name,
          UnitID: unitInfo ? unitInfo.unit_id : deviceSelected?.unit_id,
          DeviceID: deviceSelected?.id
        })
      if (eventStatus.leakAlertUpdated)
        trackEvent('Leak Alert Updated', {
          LeakDuration: deviceSelected?.leak_threshold_hours + ' hours',
          PropertyName: structure.name,
          PropertyID: structure.id,
          UnitName: unitInfo ? unitInfo.unit_name : deviceSelected?.unit_name,
          UnitID: unitInfo ? unitInfo.unit_id : deviceSelected?.unit_id,
          DeviceID: deviceSelected?.id
        })
      if (updatedDevicesResponse.hasOwnProperty('id')) {
        setEventStatus({ highLeakAlertUpdated: false, leakAlertUpdated: false })
        closeModal()
        refreshData()
      }
    } else {
      let batchDeviceValues = {}
      if (openLeakAlertModal) {
        batchDeviceValues = {
          leak_enabled: true,
          leak_threshold_hours: Number(deviceSelected.leak_threshold_hours)
        }
      }
      if (openHighLeakAlertModal) {
        batchDeviceValues = {
          desired_usage_alert_enabled: true,
          desired_usage_alert_window_seconds: Number(deviceSelected.desired_usage_alert_window_seconds),
          desired_usage_alert_threshold_gallons: Number(deviceSelected.desired_usage_alert_threshold_gallons)
        }
      }

      const updateMultipleDevicesResponse = await batchEdit(selectedRows, batchDeviceValues)
      if (updateMultipleDevicesResponse.length) {
        closeModal()
        refreshData()
      }
      setSelectedRows([])
    }
    setLoading(false)
  }

  const handleRowClick = (event, device) => {
    setAnchorEl(null)
    const selectedIndex = selectedRows.indexOf(device.id)
    const selectedIndexDevices = devicesSortArray.indexOf(device)
    let newSelected: string[] = [...selectedRows]
    if (event.shiftKey && selectRowIndex > -1) {
      const start = Math.min(selectedIndexDevices, selectRowIndex)
      const end = Math.max(selectedIndexDevices, selectRowIndex)
      devicesSortArray.forEach((item, index) => {
        if (index >= start && index <= end) {
          newSelected.push(item.id)
        }
      })
      //filter to remove repeated rows
      setSelectedRows(
        newSelected.filter((value, index, self) => {
          return self.indexOf(value) === index
        })
      )
      setSelectRowIndex(-1)
      return
    }
    if (selectedIndex === -1) {
      setSelectRowIndex(selectedIndexDevices)
      newSelected.push(device.id)
    } else {
      setSelectRowIndex(-1)
      newSelected = selectedRows.filter((value) => value !== device.id)
    }
    setSelectedRows(newSelected)
  }

  const handleRightClick = (e) => {
    e.preventDefault()
    if (selectedRows.length) {
      const { clientX, clientY } = e
      const bodyHeight = document.body.clientHeight
      const clientWidth = document.body.clientWidth
      const thresholdY = 100
      const thresholdX = 200

      if (bodyHeight - clientY < thresholdY) {
        setMouseY(bodyHeight - 220)
      } else {
        setMouseY(clientY - 50)
      }

      if (clientWidth - clientX < thresholdX) {
        setMouseX(clientX - 280)
      } else {
        setMouseX(clientX - 20)
      }
      setOpenDialog(true)
    }
  }

  const selectAllElement = () => {
    setAnchorEl(null)
    if (devicesSortArray.length === selectedRows.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(devicesSortArray.map((item) => item.id).flat())
    }
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const openGlobalLeakAlert = () => {
    setDeviceSelected(initialDevice)
    setOpenLeakAlertModal(true)
  }

  const openGlobalHighLeakAlert = () => {
    setDeviceSelected(initialDevice)
    setOpenHighLeakAlertModal(true)
  }

  const disableAllHFLA = async () => {
    try {
      setLoading(true)
      let batchDeviceValues = {
        desired_usage_alert_enabled: false,
        desired_usage_alert_window_seconds: DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_WINDOW_SECONDS,
        desired_usage_alert_threshold_gallons: DEFAULT_BATCH_EDIT_DESIRED_USAGE_ALERT_THRESHOLD_GALLONS
      }

      await batchEdit(selectedRows, batchDeviceValues)
      setLoading(false)
      setOpenHflaAlert(false)
      setSelectedRows([])
      refreshData()
      setOpenDialog(false)
    } catch (error) {}
  }

  const disableAllLA = async () => {
    try {
      setLoading(true)
      let batchDeviceValues = {
        leak_enabled: false,
        leak_threshold_hours: DEFAULT_BATCH_EDIT_LEAK_THRESHOLD_HOURS
      }

      await batchEdit(selectedRows, batchDeviceValues)
      setLoading(false)
      setOpenLaAlert(false)
      setSelectedRows([])
      refreshData()
      setOpenDialog(false)
    } catch (error) {}
  }
  const closeDisableAllHFLA = async () => {
    setOpenHflaAlert(false)
    setOpenDialog(false)
    handleClose()
  }

  const closeDisableAllLA = async () => {
    setOpenLaAlert(false)
    setOpenDialog(false)
    handleClose()
  }

  return (
    <div>
      {activateMultipleSelectors && (
        <Box display="flex">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={devicesSortArray.length === selectedRows.length}
                  onClick={selectAllElement}
                  color="primary"
                />
              }
              label="Select All"
            />
          </FormGroup>
          {!!selectedRows.length && (
            <div>
              <IconButton
                aria-label="configure devices"
                aria-controls="menu-devices"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-devices"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={open}
                onClose={handleClose}
              >
                <BatchEditMenu
                  openGlobalLeakAlert={openGlobalLeakAlert}
                  openGlobalHighLeakAlert={openGlobalHighLeakAlert}
                  openGlobalHighLeakAlertDialog={() => setOpenHflaAlert(true)}
                  openGlobalLeakAlertDialog={() => setOpenLaAlert(true)}
                />
              </Menu>
            </div>
          )}
        </Box>
      )}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {activateMultipleSelectors && <TableCell align="center" sx={{ position: 'relative' }} />}
              {activateMultipleSelectors && (
                <TableCell align="center" sx={{ position: 'relative' }}>
                  Unit
                  <CustomLabelSort onClick={() => sortColumns('unitSortValue')}>
                    <OrderIcon type={sortValues.unitSortValue} />
                  </CustomLabelSort>
                </TableCell>
              )}
              <TableCell align="center" sx={{ position: 'relative' }}>
                Device
                <CustomLabelSort onClick={() => sortColumns('nameSortValue')}>
                  <OrderIcon type={sortValues.nameSortValue} />
                </CustomLabelSort>
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Connection Status
                <CustomLabelSort onClick={() => sortColumns('statusSortValue')}>
                  <OrderIcon type={sortValues.statusSortValue} />
                </CustomLabelSort>
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Connection Status Message
                <CustomLabelSort onClick={() => sortColumns('messageSortValue')}>
                  <OrderIcon type={sortValues.messageSortValue} />
                </CustomLabelSort>
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Last updated
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Leak Alert
                <CustomLabelSort onClick={() => sortColumns('leakAlertValue')}>
                  <OrderIcon type={sortValues.leakAlertValue} />
                </CustomLabelSort>
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                High Flow Leak Alert
                <CustomLabelSort onClick={() => sortColumns('flowLeakValue')}>
                  <OrderIcon type={sortValues.flowLeakValue} />
                </CustomLabelSort>
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devicesSortArray.map((device, idx) => {
              return (
                <TableRow
                  key={device.name}
                  selected={selectedRows.includes(device.id)}
                  onContextMenu={handleRightClick}
                >
                  {activateMultipleSelectors && (
                    <TableCell align="center">
                      <Checkbox
                        checked={selectedRows.includes(device.id)}
                        onClick={(event) => handleRowClick(event, device)}
                        color="primary"
                      />
                    </TableCell>
                  )}
                  {activateMultipleSelectors && <TableCell align="center">{device.unit_name}</TableCell>}
                  <TableCell align="center">{device.name}</TableCell>
                  <TableCell align="center">
                    {device.device_status !== 'NO_DEVICES' && (
                      <StatusIcon color={STATUS_COLORS[device.device_status]} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {device.device_status_strings.map((str, idx) => (
                      <Typography variant="body1" key={idx}>
                        {str}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">{convertTimestampToTimeZone(device.last_updated, timeZone)}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={device.leak_enabled} sx={{ cursor: 'default' }} disabled />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={device.desired_usage_alert_enabled} sx={{ cursor: 'default' }} disabled />
                  </TableCell>
                  <TableCell align="center">
                    <EditPencilIcon clickHandle={() => handleOpenEditModal(device)} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {!!!devicesSortArray.length && (
          <p style={{ margin: 'auto', padding: '25px', textAlign: 'center' }}>
            No devices added. SimpleSUB support will contact you once they add the devices to your units.
          </p>
        )}
        <ModalContainer
          open={openEditModal}
          handleClose={() => setOpenEditModal(false)}
          title="Device Settings"
          save={saveDeviceData}
          loading={loading}
        >
          <DeviceSettings
            device={deviceSelected}
            updateDeviceData={(value, key) => updateDeviceData(value, key)}
            openLeakAlertModal={() => setOpenLeakAlertModal(true)}
            openHighLeakAlertModal={() => setOpenHighLeakAlertModal(true)}
            structure={structure}
            unitInfo={unitInfo}
          />
        </ModalContainer>
        <ModalContainer
          open={openLeakAlertModal}
          handleClose={() => setOpenLeakAlertModal(false)}
          title="Leak Alert"
          save={saveDeviceData}
          showBack={openEditModal}
          loading={loading}
        >
          <LeakAlert device={deviceSelected} updateDeviceData={(value, key) => updateDeviceData(value, key)} />
        </ModalContainer>
        <ModalContainer
          open={openHighLeakAlertModal}
          handleClose={() => setOpenHighLeakAlertModal(false)}
          title="High Flow Leak Alert"
          save={saveDeviceData}
          showBack={openEditModal}
          loading={loading}
        >
          <HighFlowLeakAlert device={deviceSelected} updateDeviceData={(value, key) => updateDeviceData(value, key)} />
        </ModalContainer>
      </TableContainer>
      <ModalContainer
        loading={loading}
        open={openLeakAlertModal}
        handleClose={() => setOpenLeakAlertModal(false)}
        title="Leak Alert"
        save={saveDeviceData}
        showBack={openEditModal}
      >
        <LeakAlert device={deviceSelected} updateDeviceData={(value, key) => updateDeviceData(value, key)} />
      </ModalContainer>
      <ModalContainer
        loading={loading}
        open={openHighLeakAlertModal}
        handleClose={() => setOpenHighLeakAlertModal(false)}
        title="High Flow Leak Alert"
        save={saveDeviceData}
        showBack={openEditModal}
      >
        <HighFlowLeakAlert device={deviceSelected} updateDeviceData={(value, key) => updateDeviceData(value, key)} />
      </ModalContainer>
      <DeleteConfirmDialog
        loading={loading}
        open={openHflaAlert}
        onClose={closeDisableAllHFLA}
        onConfirm={disableAllHFLA}
        title={'Do you really want to disable these leak alerts?'}
      />
      <DeleteConfirmDialog
        loading={loading}
        open={openLaAlert}
        onClose={closeDisableAllLA}
        onConfirm={disableAllLA}
        title={'Do you really want to disable these leak alerts?'}
      />
      <DialogMenu mouseX={mouseX} mouseY={mouseY} open={openDialog} onClose={() => setOpenDialog(false)}>
        <BatchEditMenu
          openGlobalLeakAlert={openGlobalLeakAlert}
          openGlobalHighLeakAlert={openGlobalHighLeakAlert}
          openGlobalHighLeakAlertDialog={() => setOpenHflaAlert(true)}
          openGlobalLeakAlertDialog={() => setOpenLaAlert(true)}
        />
      </DialogMenu>
    </div>
  )
}
