import makeStyles from '@mui/styles/makeStyles'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import { SWLoadingButtonOutlined } from '@/components/SWLoadingButtonOutlined'
import { Unit } from '@/Units/types'
import { useRef } from 'react'
import Papa from 'papaparse'
import * as XLSX from 'xlsx'
import { ISubscibersBody } from '../types'
import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

const useStyles = makeStyles({
  paper: {
    minHeight: 560
  }
})

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: Function
  loading?: boolean
  units: Unit[]
}

export const FormBulkSubscriber = ({ open, onClose, onSubmit, loading = false, units }: Props) => {
  const classes = useStyles()
  const [isWrongData, setIsWrongData] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [fileType, setFileType] = React.useState('')

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true)
    const file = event.target.files?.[0]
    if (file) {
      const extension = file.name.split('.').pop()?.toLowerCase()

      if (extension === 'csv') {
        setFileType('CSV')
        handleCsvFile(file)
      } else if (extension === 'xlsx') {
        setFileType('XLSX')
        handleXlsxFile(file)
      } else {
        setIsWrongData(true)
        setIsLoading(false)
        resetFileInput()
      }
    }
  }

  const handleCsvFile = (file: File) => {
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        processData(results.data)
      },
      error: (error) => {
        setIsWrongData(true)
        setIsLoading(false)
        resetFileInput()
      }
    })
  }

  const handleXlsxFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target) {
        const data = new Uint8Array(event.target.result as ArrayBuffer)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheet = workbook.Sheets[workbook.SheetNames[0]]
        const jsonData: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1 })

        if (jsonData.length === 0) {
          console.error('The file is empty.')
          setIsLoading(false)
          resetFileInput()
          return
        }

        const headers: string[] = jsonData[0] as string[]
        const parsedData = jsonData.slice(1).map((row: any[]) => {
          const rowData: any = {}
          headers.forEach((header: string, index: number) => {
            rowData[header] = String(row[index])
          })
          return rowData
        })

        processData(parsedData)
      } else {
        setIsWrongData(true)
        setIsLoading(false)
        resetFileInput()
      }
    }
    reader.readAsArrayBuffer(file)
  }

  const processData = (jsonData: any[]) => {
    jsonData = jsonData.filter((item: any) => item['Unit Name'].trim() !== '')
    if (jsonData.length === 0) {
      setIsWrongData(true)
      setIsLoading(false)
      resetFileInput()
      return
    }
    const isValid = jsonData.every((item: any) => item['Unit Name'] && item['Email Address'])

    if (isValid) {
      const subscribers = jsonData.map((item: any) => {
        const unit = units.find((u) => u.name === item['Unit Name'])
        return {
          email: item['Email Address'],
          first_name: item['First Name'],
          last_name: item['Last Name'],
          unit_subscriptions: unit ? [{ unit_id: unit.id }] : []
        }
      })

      const hasInvalidUnit = subscribers.some((subscriber) => subscriber.unit_subscriptions.length === 0)

      if (hasInvalidUnit) {
        setIsWrongData(true)
        setIsLoading(false)
        resetFileInput()
      } else {
        setIsWrongData(false)
        let data: ISubscibersBody = {
          subscribers: subscribers
        }
        onSubmit(data, fileType)
          .then(() => {
            setIsLoading(false)
            onClose()
          })
          .catch(() => {
            setIsLoading(false)
            resetFileInput()
          })
      }
    } else {
      setIsWrongData(true)
      setIsLoading(false)
      resetFileInput()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setIsWrongData(false)
        onClose()
      }}
      aria-labelledby="form-dialog-title"
      maxWidth={'sm'}
      fullWidth
      classes={{
        paper: classes.paper
      }}
    >
      <DialogTitle id="form-dialog-title" sx={{ margin: 'auto', textAlign: 'center', paddingTop: 5 }}>
        <Stack direction={'column'}>
          <Box sx={{ fontSize: '42px', fontWeight: 'bold' }}>
            {isWrongData ? 'Error importing tenants' : 'Import new tenants'}
          </Box>
          <Box sx={{ fontSize: '18px' }}>
            {isWrongData
              ? 'We tried uploading your file, but received errors.'
              : 'Import a list of new tenants and assign them to a property'}
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ margin: 3 }}>
        {!isWrongData && <RenderInfo />}
        {isWrongData && <RenderWarningInfo />}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between !important', margin: 2 }}>
        <SWLoadingButtonOutlined onClick={onClose}>Cancel</SWLoadingButtonOutlined>
        <>
          <LoadingButton loading={isLoading} variant="contained" color="secondary" onClick={handleButtonClick}>
            Upload
          </LoadingButton>
          <input
            ref={fileInputRef}
            type="file"
            accept=".csv,.xlsx"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </>
      </DialogActions>
    </Dialog>
  )
}

const RenderInfo = () => (
  <Stack spacing={3}>
    <Box>
      <span style={{ fontWeight: 'bold' }}>
        1. <a href="/tenants_template.csv">Download this template.</a>
      </span>{' '}
      This pre-formatted spreadsheet is designed to ensure your information is accurately captured and processed
      efficiently.
    </Box>
    <Box>
      <span style={{ fontWeight: 'bold' }}>2. Add your tenant’s information.</span> Please fill out the spreadsheet with
      your data. All fields are required.
      <Box paddingLeft={2} paddingTop={1}>
        <ul style={{ margin: 0 }}>
          <li>Unit Name</li>
          <li>Email Address</li>
          <li>First Name (optional)</li>
          <li>Last Name (optional)</li>
        </ul>
      </Box>
    </Box>
    <Box>
      <span style={{ fontWeight: 'bold' }}>3. Save and Upload.</span> Once you have filled out the spreadsheet with your
      information, save the file. Return to this page and click on the "Upload Spreadsheet" button. Select your file and
      submit it for processing.
    </Box>
  </Stack>
)

const RenderWarningInfo = () => (
  <Stack spacing={3}>
    <Box>
      <Box>Please check the data in your spreadsheet. Here’s a few common issues...</Box>
      <Box paddingLeft={2} paddingTop={1}>
        <ol style={{ margin: 0 }}>
          <li>Make sure your unit name is in the spreadsheet match your unit names in the web application</li>
          <li>Double check there are valid email addresses in the email address field</li>
          <li>Make sure the file is a CSV or XLSX.</li>
        </ol>
      </Box>
    </Box>
    <Box>Still need help? Contact support@simplesubwater.com</Box>
  </Stack>
)

export default FormBulkSubscriber
