import './global.css'
import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import StructureForm from './Structures/Create'
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react'
import { confirmSignUp, signIn, signUp } from 'aws-amplify/auth'
import { AppShell } from './components/AppShell'
import { ErrorBoundary } from './components/ErrorBoundary'
import InitialLoader from './components/Loader/InitialLoader'
import { routes } from './app-routes'
import { log } from './util'
import { useStructures } from './Structures/useStructures'
import { Structure } from './Structures/View'
import { Unit } from './Units/View'
import ResetPasswordPage from '@/pages/ResetPassword'
import Home from './pages/Home'
import '@aws-amplify/ui-react/styles.css'
import { Header } from './Authenticator/Header'
import { useAdminAccess } from './useAdminAccess'
import ConfirmResetPassword from '@/Authenticator/ConfirmResetPassword'
import SignIn from '@/Authenticator/SignIn'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { I18n } from 'aws-amplify/utils'
import { translations } from '@aws-amplify/ui-react'
import AccountSettings from './pages/AccountSettings'
import ReportPage from './pages/report'
import { getUserInfo } from './pages/AccountSettings/api'
import useMixPanel from './hooks/useMixPanel'

I18n.putVocabularies(translations)
I18n.setLanguage('en')

I18n.putVocabularies({
  en: {
    Confirm: 'Log in',
    Confirming: 'Log in'
  }
})

const LOG_CAT = 'app'

const App = (): JSX.Element => {
  const { structures } = useStructures()

  React.useEffect(() => {
    setTimeout(() => {
      window.history.replaceState('', document.title, window.location.pathname)
    })
  }, [])

  log.info(LOG_CAT, 'Properties', { structures })
  return (
    <ErrorBoundary>
      <React.Fragment>
        <ToastContainer />
        <CssBaseline />
        {!structures ? (
          <InitialLoader text={'Loading'} />
        ) : (
          <Routes>
            <Route path="/accounts" element={<AppShell hideDrawer />}>
              <Route path="reset-password" element={<ResetPasswordPage />} />
            </Route>

            <Route path="/account" element={<AppShell />}>
              <Route path="settings" element={<AccountSettings />} />
            </Route>

            <Route
              path="/"
              element={
                <>
                  <AppShell />
                  {structures && structures?.length && structures?.length === 0 ? <StructureForm /> : null}
                </>
              }
            >
              <Route index element={<Home />} />
              <Route path={routes.unit} element={<Unit />} />
              <Route path={routes.property} element={<Structure />} />
            </Route>
          </Routes>
        )}
      </React.Fragment>
    </ErrorBoundary>
  )
}

export default function RootApp() {
  const { trackEvent, setUser } = useMixPanel()
  const adminAccess = useAdminAccess()
  const isAdminHash = window.location.hash.includes('admin')

  const isAdmin = isAdminHash && adminAccess.isAdminLogin

  React.useEffect(() => {
    if (window.location.hash.includes('admin')) {
      if (!adminAccess.isAdminLogin) {
        adminAccess.updateAdminInfo({ adminEmail: adminAccess.adminEmail, isAdminLogin: true })
      } else {
        adminAccess.updateAdminInfo({ adminEmail: adminAccess.adminEmail, isAdminLogin: false })
        setTimeout(() => {
          window.history.replaceState('', document.title, window.location.pathname)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const services = {
    async handleSignIn(formData) {
      let { username, password } = formData
      const adminInfo = JSON.parse(window.localStorage.getItem('adminInfo') || '{}')
      const isAdminLogin = adminInfo.isAdminLogin
      const adminInputField: HTMLInputElement = document.getElementById('admin-email') as HTMLInputElement
      const adminEmail = adminInputField ? adminInputField.value : ''
      try {
        const res = await signIn({
          username,
          password,
          options: {
            authFlowType: 'USER_PASSWORD_AUTH'
          }
        })
        if (isAdminLogin) {
          window.localStorage.setItem('adminInfo', JSON.stringify({ ...adminInfo, adminEmail }))
        }
        if (res.isSignedIn) {
          const userInfo = await getUserInfo()
          setUser(userInfo.user.id, { $email: userInfo.user.email, $name: userInfo.user.email })
          await trackEvent('User Login', { id: userInfo.user.id })
        }
        return res
      } catch (err) {
        throw err
      }
    },
    async handleSignUp(formData) {
      let { username, password, options } = formData
      const signUpResult = await signUp({
        username,
        password,
        options
      })
      setUser(signUpResult.userId, { $email: username, $name: username })
      await trackEvent('User Created', { id: signUpResult.userId })
      return signUpResult
    },
    async handleConfirmSignUp(formdata) {
      let { username, confirmationCode } = formdata
      try {
        const confirmSignUpResult = await confirmSignUp({
          username,
          confirmationCode
        })
        await trackEvent('User Confirmed')
        return confirmSignUpResult
      } catch (error) {
        console.log(error)
        throw error
      }
    }
  }

  const formFields = isAdmin
    ? {
        signIn: {
          adminEmail: {
            labelHidden: true,
            placeholder: 'Enter Your Admin Email Here',
            isRequired: true,
            type: 'email',
            // @ts-ignore
            id: 'admin-email'
          }
        }
      }
    : undefined

  console.log('############', adminAccess.isAdminLogin, isAdminHash)

  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/report/:reportID" element={<AppShell hideDrawer />}>
            <Route path="" element={<ReportPage />} />
          </Route>
          <Route
            path="*"
            element={
              <Authenticator
                components={{
                  Header,
                  ConfirmResetPassword: { Header: ConfirmResetPassword.Header },
                  SignIn: { Header: isAdmin ? SignIn.Header : undefined },
                  ConfirmSignUp: {
                    Header() {
                      return (
                        <div>
                          <h1 style={{ textAlign: 'center' }}>Check your email</h1>
                          <p style={{ textAlign: 'center' }}>
                            Please input your one-time access code below to confirm your email. It may take a few
                            minutes to arrive.
                          </p>
                        </div>
                      )
                    },
                    Footer() {
                      return (
                        <span style={{ color: '#787f85', fontSize: '12px', textAlign: 'center' }}>
                          Didn't receive the email? Check out our{' '}
                          <a
                            href="https://simplesubwater.zendesk.com/hc/en-us/articles/24355751636884-Email-Delivery-Troubleshooting"
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{ color: '#787f85' }}
                          >
                            troubleshooting guide.
                          </a>
                        </span>
                      )
                    }
                  }
                }}
                loginMechanisms={['email']}
                variation="modal"
                services={services}
                hideSignUp={isAdmin}
                formFields={formFields}
              >
                <App />
              </Authenticator>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}
