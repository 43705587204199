import makeStyles from '@mui/styles/makeStyles'
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { SWLoadingButtonOutlined } from '@/components/SWLoadingButtonOutlined'
import { SWLoadingButton } from '@/components/LoadingButton'
import { Unit } from '@/Units/types'
import { IFormData } from './types'
import React from 'react'
import { ISubscibersBody } from '../types'

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 560
  },
  input: {
    margin: theme.spacing(1),
    marginTop: '10px'
  }
}))

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: Function
  type: 'ADD' | 'EDIT'
  loading?: boolean
  units: Unit[]
  editData?: IFormData
}

export const FormDialogSubscriber = ({ open, type, onClose, onSubmit, loading = false, units, editData }: Props) => {
  const classes = useStyles()
  const [errValUnit, setErrValUnit] = React.useState(false)
  const [errValEmail, setErrValEmail] = React.useState({ email: false, emailErrMsj: '' })
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const [formData, setFormData] = React.useState<IFormData>({
    id: '',
    unit: { label: '', id: '' },
    email: '',
    fist_name: '',
    last_name: ''
  })

  React.useEffect(() => {
    if (editData) setFormData(editData)
  }, [editData])

  const transformUnits = (units: Unit[]): { label: string; id: string }[] => {
    return units.map((unit) => ({
      label: unit.name,
      id: unit.id
    }))
  }

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSubmit = async () => {
    if (formData.email === '') setErrValEmail({ ...errValEmail, email: true, emailErrMsj: 'Required' })
    if (formData.unit.id === '') setErrValUnit(true)
    if (formData.email !== '' && !validateEmail(formData.email))
      setErrValEmail({ ...errValEmail, email: true, emailErrMsj: 'Invalid Email Format' })
    if (formData.email !== '' && formData.unit.id !== '' && validateEmail(formData.email)) {
      setIsLoading(true)
      let data: ISubscibersBody = {
        subscribers: [
          {
            id: formData.id,
            email: formData.email,
            first_name: formData.fist_name,
            last_name: formData.last_name,
            unit_subscriptions: [
              {
                unit_id: formData.unit.id
              }
            ]
          }
        ]
      }
      if (type === 'EDIT')
        await onSubmit(data.subscribers[0], { unit_id: formData.unit.id, unit_name: formData.unit.label })
      if (type === 'ADD') await onSubmit(data, { unit_id: formData.unit.id, unit_name: formData.unit.label })
      setFormData({
        id: '',
        unit: { label: '', id: '' },
        email: '',
        fist_name: '',
        last_name: ''
      })
      setIsLoading(false)
      onClose()
    }
  }

  const transformedUnits = transformUnits(units)
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'sm'}
      fullWidth
      classes={{
        paper: classes.paper
      }}
    >
      <DialogTitle id="form-dialog-title" sx={{ margin: 'auto', textAlign: 'center', paddingTop: 5 }}>
        <Stack direction={'column'}>
          <Box sx={{ fontSize: '42px', fontWeight: 'bold' }}>{type === 'EDIT' ? 'Edit' : 'Create'} tenant</Box>
          <Box sx={{ fontSize: '18px' }}>
            {type === 'EDIT' ? 'Update this tenant’s information below' : 'Add a new tenant and assign them to a unit'}
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ margin: 3 }}>
        <Stack spacing={2}>
          <Autocomplete
            value={formData.unit}
            onChange={(event, newValue) => {
              const value = newValue ? newValue : formData.unit
              setFormData({ ...formData, unit: value })
            }}
            sx={{ paddingTop: 3 }}
            options={transformedUnits}
            renderInput={(params) => (
              <TextField
                error={errValUnit}
                {...params}
                label="Unit Name"
                required
                variant="outlined"
                helperText={errValUnit ? 'Required' : ''}
              />
            )}
          />
          <TextField
            label="Email Address *"
            error={errValEmail.email}
            margin="none"
            type="email"
            value={formData.email}
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value })
            }}
            variant="outlined"
            className={classes.input}
            fullWidth
            helperText={errValEmail.emailErrMsj}
          />
          <TextField
            label="First Name"
            margin="none"
            value={formData.fist_name}
            onChange={(e) => {
              setFormData({ ...formData, fist_name: e.target.value })
            }}
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Last Name"
            margin="none"
            value={formData.last_name}
            onChange={(e) => {
              setFormData({ ...formData, last_name: e.target.value })
            }}
            variant="outlined"
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between !important', margin: 2 }}>
        <SWLoadingButtonOutlined onClick={onClose}>Cancel</SWLoadingButtonOutlined>
        <SWLoadingButton onClick={() => handleSubmit()} loading={isLoading}>
          {type === 'EDIT' ? 'Done' : 'Create'}
        </SWLoadingButton>
      </DialogActions>
    </Dialog>
  )
}
