import { ServerDevice } from '@/Devices/types'
import { Box, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import { green } from '@mui/material/colors'
import { alpha, styled } from '@mui/material/styles'
import { SECONDS_CONSTANT } from '../DeviceStatusTable'
import useMixPanel from '@/hooks/useMixPanel'
import { Structure } from '@/Structures/types'

type props = {
  device: ServerDevice | undefined
  updateDeviceData: (value: boolean | string, key: string) => void
  openLeakAlertModal: () => void
  openHighLeakAlertModal: () => void
  structure: Structure
  unitInfo?: { unit_id: string; unit_name: string }
}

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[600],
    '&:hover': {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[600]
  }
}))

export const DeviceSettings = ({
  device,
  structure,
  unitInfo,
  updateDeviceData,
  openLeakAlertModal,
  openHighLeakAlertModal
}: props) => {
  const { trackEvent } = useMixPanel()
  const desired_usage_alert_window_hours = Math.round(
    (device?.desired_usage_alert_window_seconds || 0) / SECONDS_CONSTANT.SECONDS_IN_AN_HOUR
  )

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          Serial Number:
        </Grid>
        <Grid item xs={6}>
          {device?.serial_number}
        </Grid>
        <Grid item xs={6}>
          Connection Status:
        </Grid>
        <Grid item xs={6}>
          {device?.device_status_strings.join(',')}
        </Grid>
      </Grid>
      <Box mt="25px">
        <TextField
          id="outlined-required"
          label="Device Name"
          defaultValue={device?.name}
          fullWidth={true}
          onChange={(value) => updateDeviceData(value.target.value, 'name')}
        />
      </Box>
      <Typography my="25px" fontSize="16px" color="gray">
        <b>Usage Alerts</b>
      </Typography>
      <Grid container rowSpacing={1}>
        <Grid item xs={6} fontSize="18px">
          <b>Leak Alert</b>
        </Grid>
        <Grid item xs={6} textAlign="end">
          <GreenSwitch
            defaultChecked={device?.leak_enabled}
            onChange={(value) => {
              const eventData = {
                LeakDuration: device?.leak_threshold_hours + ' hours',
                PropertyName: structure.name,
                PropertyID: structure.id,
                UnitName: unitInfo ? unitInfo.unit_name : device?.unit_name,
                UnitID: unitInfo ? unitInfo.unit_id : device?.unit_id,
                DeviceID: device?.id
              }
              if (value.target.checked) trackEvent('Leak Alert Enabled', eventData)
              if (!value.target.checked) trackEvent('Leak Alert Disabled', eventData)
              updateDeviceData(value.target.checked, 'leak_enabled')
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          onClick={() => {
            if (!!device?.leak_enabled) openLeakAlertModal()
          }}
          sx={[
            !!device?.leak_enabled && {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            },
            !device?.leak_enabled && {
              opacity: '50%'
            }
          ]}
        >
          Optimal for low flow leaks <br />
          Notify me if water is running for <b>{device?.leak_threshold_hours} hours</b>
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} mt="25px">
        <Grid item xs={6} fontSize="18px">
          <b>High Flow Leak Alert</b>
        </Grid>
        <Grid item xs={6} textAlign="end">
          <GreenSwitch
            defaultChecked={device?.desired_usage_alert_enabled}
            onChange={(value) => {
              const eventData = {
                LeakDuration: desired_usage_alert_window_hours + ' hours',
                PropertyName: structure.name,
                PropertyID: structure.id,
                UnitName: unitInfo ? unitInfo.unit_name : device?.unit_name,
                UnitID: unitInfo ? unitInfo.unit_id : device?.unit_id,
                DeviceID: device?.id
              }
              if (value.target.checked) trackEvent('High Flow Leak Alert Enabled', eventData)
              if (!value.target.checked) trackEvent('High Flow Leak Alert Disabled', eventData)
              updateDeviceData(value.target.checked, 'desired_usage_alert_enabled')
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          onClick={() => {
            if (!!device?.desired_usage_alert_enabled) openHighLeakAlertModal()
          }}
          sx={[
            !!device?.desired_usage_alert_enabled && {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            },
            !device?.desired_usage_alert_enabled && {
              opacity: '50%'
            }
          ]}
        >
          Optimal for high flow leaks or drawing too much water from a well. <br /> Notify me if I use more than{' '}
          <b>{device?.desired_usage_alert_threshold_gallons} gal</b> in <b>{desired_usage_alert_window_hours} hours</b>
        </Grid>
      </Grid>
    </Box>
  )
}
